import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Activity } from '../models/activity';
import { Subject } from 'rxjs/internal/Subject';

export interface ActivityApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class ActivityService {
  private currentActivity;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/activities/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  public activityTypes = ['calls', 'notes', 'sms', 'submission', 'apply', 'merge'];
  public activityType = ['Call', 'Note', 'SMS', 'Submission', 'Apply', 'Merge'];
  public relatedModules = ['accounts', 'contacts', 'leads', 'providers', 'subs'];

  public activityTypesWithNames = [
    {label: 'Call', value: 'calls'},
    {label: 'Note', value: 'notes'},
    {label: 'Sms', value: 'sms'},
    {label: 'Submission', value: 'sub'},
    {label: 'Apply', value: 'apply'},
    {label: 'Merge', value: 'merge'},
  ];

  availableActivities = [
    {type: 'calls', label: 'Call', icon: 'call', image: '/uploads/system_images/icons/icons8-add-phone-50.png'},
    {type: 'email', label: 'Email', icon: 'email', image: '/uploads/system_images/icons/icons8-add-message-50.png'},
    {type: 'sms', label: 'SMS', icon: 'sms', image: '/uploads/system_images/icons/icons8-add-to-chat-50.png'},
    {type: 'notes', label: 'Note', icon: 'note_add', image: '/uploads/system_images/icons/icons8-goodnotes-50.png'},
    {type: 'sub', label: 'Submission', icon: 'sub', image: '/uploads/system_images/icons/icons8-complaint-50.png'},
    {type: 'apply', label: 'Apply', icon: 'apply', image: '/uploads/system_images/icons/icons8-accounting-50.png'},
    {type: 'merge', label: 'Merge', icon: 'apply', image: '/uploads/system_images/icons/icons8-accounting-50.png'},
  ];

  activityToPreview: Activity = null;
  activityToReplyTo = null;

  private activityCreationObs$ = new Subject<Activity>();
  private unreadMessagesObs$ = new Subject<Activity[]>();
  private requestUnreadQueueUpdateObs$ = new Subject<Activity[]>();
  private requestUnreadReadFeedUpdateObs$ = new Subject<any>();

  constructor(
    private httpClient: HttpClient
  ) { }

  listenForNewActivity() {
    return this.activityCreationObs$;
  }

  announceNewActivity(_activity: Activity) {
      this.activityCreationObs$.next(_activity);
  }


  listenForUnreadMessages() {
    return this.unreadMessagesObs$;
  }

  setUnreadActivities(_activities: Activity[]) {
    this.unreadMessagesObs$.next(_activities);
  }


  listenForUnreadUpdateReq() {
    return this.requestUnreadQueueUpdateObs$;
  }

  triggerUnreadUpdateReq(_trigger) {
    this.requestUnreadQueueUpdateObs$.next(null);
  }


  listenForUnreadReadFeedUpdate() {
    return this.requestUnreadReadFeedUpdateObs$;
  }

  requestReadUnreadUpdates(_request) {
    this.requestUnreadReadFeedUpdateObs$.next(_request);
  }



  setCurrent(_activity): void {
    this.currentActivity = _activity;
  }

  getCurrent() {
    return this.currentActivity;
  }

  getAll(): Observable<Activity[]> {
    return this.httpClient.get<Activity[]>(this.API_URL);
  }

  get(_id: string): Observable<Activity> {
    return this.httpClient.get<Activity>(this.API_URL + _id);
  }  

  create(_activity: Activity): Observable<Activity> {
    return this.httpClient.post<Activity>(this.API_URL, JSON.stringify(_activity), this.headerOptions);
  }

  getUnreadRelated(_searchTerms): Observable<Activity[]> {
    return this.httpClient.post<Activity[]>(this.API_URL + 'unread-related', JSON.stringify(_searchTerms), this.headerOptions);
  }

  update(_activity: Activity): Observable<Activity> {
    return this.httpClient.put<Activity>(`${this.API_URL}${_activity._id}`, JSON.stringify(_activity), this.headerOptions);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete<any>(this.API_URL + 'mark-deleted/' + id);
  }

  getRelatedActivity(_moduleOptions): Observable<Activity> {
    return this.httpClient.post<Activity>(this.API_URL + 'get-related-activity', JSON.stringify(_moduleOptions), this.headerOptions);
  }

  search(_moduleOptions): Observable<Activity[]> {
    return this.httpClient.post<Activity[]>(this.API_URL + 'search', JSON.stringify(_moduleOptions), this.headerOptions);
  }

  searchWithPopulates(_moduleOptions): Observable<Activity[]> {
    return this.httpClient.post<Activity[]>(this.API_URL + 'searchWithPopulates', JSON.stringify(_moduleOptions), this.headerOptions);
  }

  getCombinedActivities(_activitiesRequest): Observable<Activity[]> {
    return this.httpClient.post<Activity[]>(this.API_URL + 'get-combined-activities', JSON.stringify(_activitiesRequest), this.headerOptions);
  }

  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }

  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  pagedSearch(searchParams, sortField = 'createdAt', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<ActivityApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<ActivityApi>(this.API_URL + 'pagedSearch', JSON.stringify(searchParams), this.headerOptions); 
  }



  getCustomDatasource(searchParams, sortField = 'createdAt', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<ActivityApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<ActivityApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }


  async convertToPopulatedRelated(unmappedActivities): Promise<any[]> {
    return new Promise(async(resolve) => {
      const _activities = unmappedActivities.map(_act => {
        // can defuck here for those pesky emails shown as calls
  
        if (_act.populatedRelatedModule && _act.populatedRelatedModule.interaction_type && _act.populatedRelatedModule.interaction_type === 'Email') {
          _act.activityType = 'Email';
        }
  
        let relatedRecord = null;
  
        if (_act.lead) relatedRecord = _act.lead;
        else if (_act.account) relatedRecord = _act.account;
        else if (_act.sub) relatedRecord = _act.sub;
        else if (_act.contact) relatedRecord = _act.contact;
  
        _act.populatedRelatedRecord = relatedRecord;
  
        let relatedModule = null;
  
        if (_act.email && _act.email._id) relatedModule = _act.email;
        else if (_act.call && _act.call._id) relatedModule = _act.call;
        else if (_act.note && _act.note._id) relatedModule = _act.note;
        else if (_act.sms && _act.sms._id) relatedModule = _act.sms;
  
        _act.populatedRelatedModule = relatedModule;
  
        return _act;
      });

      resolve(_activities);
    });
  }

}