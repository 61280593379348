<div class="container voiceshot-dialog">
  <h1 mat-dialog-title>Voiceshot Campaign</h1>

  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Voiceshot</mat-label>
      <mat-select placeholder="Voiceshot" [(ngModel)]="chosenVoiceshot" name="chosenVoiceshot" (selectionChange)="voiceshotSelected($event?.value)">
        <mat-option *ngFor="let _voiceshot of availableVoiceshots" [value]="_voiceshot">
          {{ _voiceshot?.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="chosenVoiceshot?.filename" class="html-audio-preview">
      <audio [src]="sanitizedUrl" controls>
        <source>
        Your browser does not support the audio element.
      </audio>
    </div>

    <ng-container *ngIf="recipients?.length">
      <h4>Recipients:</h4>

      <div class="recipient-list">
        <div *ngFor="let rec of recipients">{{ rec?.name }} - {{ rec?.phone }}</div>
      </div>
    </ng-container>
  </mat-dialog-content>

  <div class="row pin-to-bottom">
    <button mat-raised-button color="primary" [disabled]="!chosenVoiceshot || !chosenVoiceshot?.filename" (click)="closeDialog(true)">{{ confirmText }}</button>
    <button mat-button mat-dialog-close (click)="closeDialog(false)">Cancel</button>
  </div>
</div>