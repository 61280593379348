import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { AchievementNotificationPopupData, Game, GameInstance, GamePlayerState, GameSession, User, Wallet } from '@app/models';

import { AuthService } from './auth.service';
import { AchievementService, GameActivityService, GameControllerService, GameInstanceService, GameSessionService, SharedUtilsService, UserRewardsRecognitionService, WalletService } from '.';

@Injectable({ providedIn: 'root' })
export class GameDetectorService {
  constructor(
    private _achievementService: AchievementService,
    private _gameActivityService: GameActivityService,
    private _authService: AuthService,
    private _gameControllerService: GameControllerService,
    private _gameInstanceService: GameInstanceService,
    private _gameSessionService: GameSessionService,
    private _sharedUtilsService: SharedUtilsService,
    private _userRewardsRecognitionService: UserRewardsRecognitionService,
    private _walletService: WalletService
  ) { }


  triggerGameDetector(_module, _uneditedRecord, _newRecord) {
    return new Promise(async (resolve, reject) => {
      // console.log('Triggering game detector.');
      // console.log({_module, _uneditedRecord, _newRecord});

      const currentUser = this._authService.getCurrentlyLoggedInUser();
      const currentUserAwardsAndRec = await this._userRewardsRecognitionService.getCurrentUserRewardsAndRec(currentUser._id);
      const achievementNotificationsToShow: AchievementNotificationPopupData[] = []; // Any created AchievementNotificationPopupData should be pushed here to use after function finishes

      const currentUserGames = (currentUserAwardsAndRec && currentUserAwardsAndRec.games && currentUserAwardsAndRec.games.length) ? currentUserAwardsAndRec.games.map(_g => {
        const gameInstanceId = (_g.gameInstance && _g.gameInstance._id) ? _g.gameInstance._id : _g.gameInstance;
        return gameInstanceId;
      }) : [];

      let coinsToAwardUser: number = 0;
      let coinHistoryToAddForUser: any[] = [];
      let urrNeedsUpdating = false;

      const _instanceTerms = new GameInstance();
      _instanceTerms.active = true; // Needs to be active.
      _instanceTerms.users = [currentUser._id]; // Needs this user in the user array.
      _instanceTerms.hasStarted = true; // Game needs to have been started. Instance start_date needs to be less than or equal to now.
      _instanceTerms.hasEnded = false; // Game can't be ended. Instance end_date needs to be greater than or equal to now.
      _instanceTerms.date_completed_set = false; // We want date_completed to be null or not set. Really only pertains to arcade games.

      const activeInstancesInvolvedWith: GameInstance[] = await lastValueFrom(this._gameInstanceService.search(_instanceTerms));
      // console.log('Game instances user is involved with: ', activeInstancesInvolvedWith);

      const _actionType = (!_uneditedRecord || !_uneditedRecord._id) ? 'new' : 'update';
      // console.log('This action type is: ', _actionType);
      // console.log('currentUserGames: ', currentUserGames);

      const gameInstancePromises = activeInstancesInvolvedWith
        .filter(_i => _i.game != undefined)
        .filter(_i => (_i.game.related_module != undefined && _i.game.related_module._id != undefined) && _i.game.related_module._id === _module._id)
        .filter(_i => _i.game.appliesTo != undefined && _i.game.appliesTo === _actionType)
        .filter(_i => !currentUserGames.includes(_i._id))
        .map(async (_instance, _index) => {
          // console.log("Instance: ", _instance);

          const relatedGameSession: GameSession = await this.getMatchingSession(_instance, currentUser);
          const relatedGame: Game = (_instance.game != undefined && _instance.game._id != undefined) ? _instance.game : null;
          let gameGoal: number = (relatedGame != undefined && relatedGame.goal != undefined) ? relatedGame.goal : 0;
          let amountToIncreasePlayerBy: number = 1;

          // See if record passes condition checks
          const passesChecks = await this.shouldTriggerUpdates(_module, _instance, relatedGameSession, relatedGame, _uneditedRecord, _newRecord, _actionType);

          // If all checks passed then continue.
          if (passesChecks) {
            console.log('Continuing with players game updates!');

            // Stops users from cheating by updating the same record multiple times.
            relatedGameSession.record_ids_used.push(_newRecord['_id']); // Push record id, so it will not be checked again.

            // Build game name for anything that needs it below so we don't have to repeat this.
            const gameName: string = (relatedGame.name != undefined) ? this._sharedUtilsService.titleCaseString(relatedGame.name) : 'a game';

            if (_instance.date_completed == undefined && relatedGame.templateKey === 'arcade') {
              // console.log('Amount towards next goal: ', relatedGameSession.record_ids_used.length % gameGoal);

              // If there is a multiplier set and is more than 0 then multiply amountToIncreasePlayerBy by multiplier to get the amount of credits to payout.
              if (relatedGame != undefined && relatedGame.progressMultiplier != undefined && relatedGame.progressMultiplier > 0) {
                amountToIncreasePlayerBy = amountToIncreasePlayerBy * relatedGame.progressMultiplier;
              }

              if (relatedGameSession.available_credits == undefined) relatedGameSession.available_credits = 0; // Just incase it is not present.

              let hasReceivedCredits: boolean = false;

              // If goal was not set or 0 give credits. Else if record_ids_used length see if the goal has been reached by using modulo of length % goal.
              if (gameGoal <= 0) {
                relatedGameSession.available_credits += amountToIncreasePlayerBy; // Add credits to users session.
                hasReceivedCredits = true;
              } else if (relatedGameSession.record_ids_used.length && relatedGameSession.record_ids_used.length % gameGoal === 0) {
                relatedGameSession.available_credits += amountToIncreasePlayerBy; // Add credits to users session.
                hasReceivedCredits = true;
              }

              if (hasReceivedCredits) {
                // this._sharedUtilsService.showNotification(`You have received ${amountToIncreasePlayerBy} credits for ${gameName}.`);

                // Build message for game activity.
                const creditText: string = (amountToIncreasePlayerBy === 1) ? 'credit' : 'credits';
                const messageForActivity: string = `just earned ${amountToIncreasePlayerBy} ${creditText}.`;

                // Wait for activity to be created.
                await this._gameActivityService.buildAndCreateGameActivity(_instance, currentUser, messageForActivity);

                // Pass the amount of credits to the notification dialog. If we want a special message pass it as well.
                this._gameControllerService.showCreditsNotificationDialog(amountToIncreasePlayerBy);
              }
            } else {
              // If there is a multiplier set and is more than 0 then multiply these fields by multiplier to get correct values to use.
              if (relatedGame != undefined && relatedGame.progressMultiplier != undefined && relatedGame.progressMultiplier > 0) {
                gameGoal = gameGoal * relatedGame.progressMultiplier;
                amountToIncreasePlayerBy = amountToIncreasePlayerBy * relatedGame.progressMultiplier;
              }

              let usersGoalProgress: number = 0; // Used to compare against goal to see if rewards and stuff should be setup;
              let messageForActivity: string = ''; // Message which will be built for game activity.
              if (_instance.current_state == undefined) _instance.current_state = []; // If the current_state is null make it an array.

              // See if user has entry in current_state.
              const userStateIndex = _instance.current_state.findIndex((_state) => _state.user_id != undefined && _state.user_id === currentUser._id);

              // If user entry found modify it, else create one and add it.
              if (userStateIndex > -1) {
                // Need to modify tile position, so set it or if missing set to 0 so we have something to modify to prevent errors.
                let usersTilePosition: number = (_instance.current_state[userStateIndex].current_tile != undefined) ? _instance.current_state[userStateIndex].current_tile : 0;

                // Make sure players tile position will not be greater than game goal. Make correction if this is the case. Else set it.
                if (usersTilePosition + amountToIncreasePlayerBy > gameGoal) usersTilePosition = gameGoal;
                else usersTilePosition += amountToIncreasePlayerBy;

                _instance.current_state[userStateIndex].current_tile = usersTilePosition; // Set users tile position to one built above.
                _instance.current_state[userStateIndex].action_date = new Date(); // Update the last action date to now.

                usersGoalProgress = usersTilePosition;
              } else {
                const _newPlayerState = new GamePlayerState();
                _newPlayerState.user_id = currentUser._id;
                _newPlayerState.current_tile = amountToIncreasePlayerBy;
                _newPlayerState.action_date = new Date();
                _instance.current_state.push(_newPlayerState);

                usersGoalProgress = _newPlayerState.current_tile;
              }

              // If users goal progress equals the games goal do what else needs to be done.
              if (usersGoalProgress === gameGoal) {
                // console.log('Save game and results.');
                if (_instance.results == undefined) _instance.results = []; // If the results is null make it an array.

                const positionFinished: number = _instance.results.length + 1; // Starts at 1 and each entry will be the length plus 1.

                // We don't want to save the whole user here. Its a big object and has private stuff like password.
                const resultUser = {
                  _id: (currentUser._id != undefined) ? currentUser._id : null,
                  name: (currentUser['name'] != undefined) ? currentUser['name'] : null,
                  first_name: (currentUser.first_name != undefined) ? currentUser.first_name : null,
                  last_name: (currentUser.last_name != undefined) ? currentUser.last_name : null,
                  email: (currentUser.email != undefined) ? currentUser.email : null,
                  userProfile: (currentUser.userProfile != undefined) ? currentUser.userProfile : null
                };

                _instance.results.push({ user: resultUser, finishedPosition: positionFinished, dateFinished: new Date() }); // Push new entry into results.

                // Create game entry for current users AwardsAndRec.
                const newGameEntry = { finishedPosition: positionFinished, dateFinished: new Date(), gameInstance: _instance._id };

                // Add game entry to games array so it will be filtered out next time around.
                if (currentUserAwardsAndRec.games == undefined || !currentUserAwardsAndRec.games.length) currentUserAwardsAndRec.games = [newGameEntry];
                else currentUserAwardsAndRec.games.push(newGameEntry);

                urrNeedsUpdating = true; // currentUserAwardsAndRec has been changed and will need to be updated. Do this after Promise.

                let scoreForAchievement = 0; // Assign points to this in rewards check below if any.

                // If there are rewards then give out the rewards that are for the position they finished.
                if (relatedGame.rewards != undefined && relatedGame.rewards.length) {
                  // console.log('User position unlocked rewards.');
                  let score = 0; // award any rewards

                  for (let index = 0; index < relatedGame.rewards.length; index++) {
                    const _reward = relatedGame.rewards[index];
                    const appliedPositions: number[] = (_reward.appliedPositions != undefined) ? _reward.appliedPositions : [];

                    // See if the reward is valid and if available for finished position.
                    if (appliedPositions.includes(positionFinished) && _reward.rewardType != undefined && _reward.amount != undefined) {
                      currentUserAwardsAndRec.rewards.push({ dateRewarded: new Date(), reward: { rewardType: _reward.rewardType, amount: _reward.amount, reward_source: 'Game' } });

                      // If reward is points add it to pointsHistory and increase score. If reward is coins, get users wallet and award coins, then update.
                      if (_reward.rewardType === 'points' && _reward.amount > 0) {
                        currentUserAwardsAndRec.pointsHistory.push({ dateApplied: new Date(), points: _reward.amount, actionType: 'added', reason: relatedGame.name + ' completed', source_type: 'Game', modifiedBy: null });
                        score += _reward.amount;
                      } else if (_reward.rewardType === 'coins' && _reward.amount > 0) {
                        coinHistoryToAddForUser.push({ dateApplied: new Date(), count: _reward.amount, actionType: 'added', reason: relatedGame.name + ' completed', source_type: 'Game', modifiedBy: null });
                        coinsToAwardUser += _reward.amount;
                      }
                    }
                  }

                  currentUserAwardsAndRec.pointsBalance += score; // update points balance here
                  scoreForAchievement = score; // Add score to this so it can be shown in achievement popup.
                }

                // Create and push popupData to be shown after map is finished.
                const gameBadge: string = (relatedGame.trophy != undefined) ? relatedGame.trophy : '/uploads/system_images/icons/icons8-game-controller-50.png';
                const popupData = new AchievementNotificationPopupData(`Game ${relatedGame.name} Completed`, scoreForAchievement, false, false, gameBadge);
                achievementNotificationsToShow.push(popupData);

                // Build message for activity.
                messageForActivity = `finished in position ${positionFinished} in ${gameName}.`;
              } else {
                // Build message for activity.
                messageForActivity = `has reached tile ${usersGoalProgress} in ${gameName}.`;
              }

              // Wait for activity to be created.
              await this._gameActivityService.buildAndCreateGameActivity(_instance, currentUser, messageForActivity);

              const updatedInstance = await lastValueFrom(this._gameInstanceService.update(_instance)); // Update the instance with changes.
              _instance = updatedInstance; // Need updated instance for announcements.
            }

            await lastValueFrom(this._gameSessionService.update(relatedGameSession)); // Update the session with changes.

            // Announce to listeners that the game instance has changed. Should still get from db in listeners since sessions and activities may be out of sync.
            this._gameInstanceService.announceInstanceChange(_instance);
          } else {
            console.log('Failed condition checks!');
          }
        });

      Promise.all(gameInstancePromises).then(async () => {
        if (coinsToAwardUser > 0) await this.assignCoinsToUserWallet(currentUser, coinsToAwardUser, coinHistoryToAddForUser);

        if (urrNeedsUpdating) {
          const updatedRewardsAndRec = await lastValueFrom(this._userRewardsRecognitionService.update(currentUserAwardsAndRec));
          this._userRewardsRecognitionService.setCurrentUserAchievements(updatedRewardsAndRec);
        }

        // If popupData was pushed to array set them and trigger function to show them.
        if (achievementNotificationsToShow.length) {
          this._achievementService.setNotificationsToShow(achievementNotificationsToShow); // Set AchievementNotificationPopupData[] on service.
          this._achievementService.showMultiAchievementNotification(); // Trigger function to show achievement notifications
        }

        resolve(null);
      });
    });
  }


  getMatchingSession(_instance: GameInstance, _currentUser: User): Promise<GameSession> {
    return new Promise(async (resolve) => {
      let usersSessionForInstance: GameSession = null;

      // See if _instance has a session for user in its game_sessions array first.
      if (_instance.game_sessions != undefined && _instance.game_sessions.length) {
        const populatedSessionIndex = _instance.game_sessions.findIndex((_session) => _session.user != undefined && ((_session.user._id != undefined && _session.user._id === _currentUser._id) || _session.user === _currentUser._id));
        if (populatedSessionIndex > -1) usersSessionForInstance = _instance.game_sessions[populatedSessionIndex];
      }

      // Session was not found above or was missing data. So see if one is in db and was left out first. If not create one.
      if (usersSessionForInstance == undefined || usersSessionForInstance._id == undefined) {
        // console.log('Session was not in _instance.game_sessions. So look for one or create.');
        const foundSession = await lastValueFrom(this._gameSessionService.searchForOne({ game_instance: _instance, user: _currentUser._id }));

        // If one was found use it. Else create one and use it.
        if (foundSession != undefined) {
          usersSessionForInstance = foundSession;
        } else {
          const _newGameSession = new GameSession();
          _newGameSession.game_instance = _instance._id;
          _newGameSession.user = _currentUser._id;
          _newGameSession.current_state = null;
          _newGameSession.record_ids_used = null;
          _newGameSession.points = 0;
          _newGameSession.available_credits = 0;
          _newGameSession.used_credits = 0;
          _newGameSession.last_time_viewed = null;

          const _createdSession = await lastValueFrom(this._gameSessionService.create(_newGameSession));
          usersSessionForInstance = (_createdSession != undefined && _createdSession._id != undefined) ? _createdSession : null;
        }
      }

      resolve(usersSessionForInstance);
    });
  }


  shouldTriggerUpdates(_module, _instance, _relatedGameSession, _relatedGame, _uneditedRecord, _newRecord, _updateType): Promise<boolean> {
    return new Promise(async (resolve) => {
      // must pass all game conditions in order to trigger actions/alerts
      let _passedChecks = false;

      // Need to make sure _relatedGameSession is valid for updating after checks and need _relatedGame for checks.
      if ((_relatedGameSession != undefined && _relatedGameSession._id != undefined) && (_relatedGame != undefined && _relatedGame.appliesTo != undefined)) {
        if (_relatedGameSession.record_ids_used == undefined) _relatedGameSession.record_ids_used = []; // Need array to check and push to.

        const fieldToWatch = (_relatedGame.fieldToWatch != undefined && _relatedGame.fieldToWatch['fieldName'] != undefined) ? _relatedGame.fieldToWatch['fieldName'] : null;
        const fieldToWatchValue = (_relatedGame.fieldToWatchValue != undefined && _relatedGame.fieldToWatchValue['value'] != undefined) ? this.formatField(_relatedGame.fieldToWatchValue['value']) : null;
        // console.log("Field To Watch Value: ", fieldToWatchValue);

        // _updateType is 'new' or 'update'
        if (_relatedGame.appliesTo === 'new' && _updateType === 'new') {
          _passedChecks = true;
        } else if ((_relatedGame.appliesTo === 'update' && _updateType === 'update') && (fieldToWatch != undefined && fieldToWatchValue != undefined)) {
          // Get new and old record field values to compare. Use formatField to get ids if either is a relate with objects
          const newFieldValue = (_newRecord[fieldToWatch] != undefined) ? this.formatField(_newRecord[fieldToWatch]) : null;
          const oldFieldValue = (_uneditedRecord[fieldToWatch] != undefined) ? this.formatField(_uneditedRecord[fieldToWatch]) : null;
          // console.log("New Field Value: ", newFieldValue);

          // Make sure record was not already added to records used for game so they don't get credit twice. Else compare the values to see if field has changed.
          if (_relatedGameSession.record_ids_used.includes(_newRecord['_id'])) {
            console.log('Record Id was already used for game.');
            _passedChecks = false;
          } else if (newFieldValue !== oldFieldValue) {
            // If the fieldToWatchValue is an array, need to do inclusion, exclusion, or equals checks. Else see if new record field matches fieldToWatchValue.
            if (Array.isArray(fieldToWatchValue)) {
              const fieldToWatchValueType = (_relatedGame.fieldToWatchValue['type'] != undefined) ? _relatedGame.fieldToWatchValue['type'].toLowerCase() : 'include';

              if ((fieldToWatchValueType === 'include' && fieldToWatchValue.includes(newFieldValue)) || (fieldToWatchValueType === 'exclude' && !fieldToWatchValue.includes(newFieldValue))) {
                _passedChecks = true;
              } else if (fieldToWatchValueType === 'equals') {
                // Field type edit in dependentValuePicker allows setting equals on some fields but some records can only select one.
                // So if the record is an array then check if both arrays have same items, else just do includes.
                if (Array.isArray(newFieldValue) && this.arrayItemsMatch(fieldToWatchValue, newFieldValue)) _passedChecks = true;
                else if (fieldToWatchValue.includes(newFieldValue)) _passedChecks = true;
              }
            } else if (newFieldValue === fieldToWatchValue) {
              _passedChecks = true;
            }
          }
        }
      }

      resolve(_passedChecks);
    });
  }


  formatField(_fieldToFormat) {
    let fieldFormatted = null;

    if (_fieldToFormat != undefined) {
      // Incase passed field contains objects with _ids. This means it is a relate and we need the ids.
      if (Array.isArray(_fieldToFormat)) fieldFormatted = _fieldToFormat.map((v) => v['_id'] != undefined ? v['_id'] : v);
      else fieldFormatted = (_fieldToFormat['_id'] != undefined) ? _fieldToFormat['_id'] : _fieldToFormat;
    }

    return fieldFormatted;
  }


  arrayItemsMatch(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    };

    for (let i = 0; i < array1.length; i++) {
      if (!array2.includes(array1[i])) {
        return false;
      };
    };

    return true;
  }


  assignCoinsToUserWallet(_currentUser, _coinAmount: number, _coinHistory: any[]): Promise<any> {
    return new Promise(async (resolve) => {
      if (_coinHistory == undefined) _coinHistory = []; // Needs to be an array to use in map and setting to new wallet.

      const foundWallet = await lastValueFrom(this._walletService.getForUser(_currentUser._id));
      if (foundWallet != undefined) {
        foundWallet.balance = (foundWallet.balance != undefined) ? foundWallet.balance + _coinAmount : _coinAmount;
        foundWallet.coinHistory = (foundWallet.coinHistory != undefined && foundWallet.coinHistory.length) ? foundWallet.coinHistory.concat(_coinHistory) : _coinHistory;

        await lastValueFrom(this._walletService.update(foundWallet));
      } else {
        const _newWallet = new Wallet();
        _newWallet.user = _currentUser._id;
        _newWallet.balance = _coinAmount;
        _newWallet.coinHistory = _coinHistory;

        await lastValueFrom(this._walletService.create(_newWallet));
      }

      resolve(null);
    });
  }
}