import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { cacheable } from '../utils/rxjs-functions';

import { CustomValidator } from '@app/validators/customValidators';

import { SsModule } from '@app/models/ssModule';
import { ExpiringCache, Layout } from '@app/models';
import { SharedUtilsService } from './shared-utils.service';

export class SchemaField {
  fieldName: string;
  label: string;
  type: any;
  dropdown: any;
  dependent: boolean;
  parentField: any;
  fieldsToTrigger: any;
  ref: any;
  inputType;
  textAreaRowCount: number;
  description: string;
  default: any;
  required: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SsModuleService {
  private API_URL = '/api/ss-modules/';
  private LAYOUTS_API = '/api/layouts/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private stepChanged$ = new Subject<number>();
  private customModuleChanged$ = new Subject<SsModule>();
  private customModuleChosen$ = new Subject<SsModule>();

  private currentModule: SsModule;
  private fieldUpdateData: any;

  modulesStorage: ExpiringCache = new ExpiringCache();
  public allModules: SsModule[];

  public leadStatusFieldName: string = 'lead_status'; // So we can change this in one place if it changes on module.

  public modulesToLeaveOutOfReports = ['reports', 'games']; // Don't want to create reports for these modules

  // list of modules with custom layouts and workflows
  public outOfBoxModules = ['accounts', 'activities', 'audit-log', 'clients', 'credentialingSetup', 'credentialing', 'courseEvaluation', 'courses', 'interactiveDocuments', 'jobs', 'leads', 'libraryResources', 'sms', 'subs', 'tasks', 'documents', 'users', 'hrPolicies', 'reports', 'providers', 'ptoBalance', 'ptoRequests', 'yeehroMessage', 'games', 'commissions'];

  public defaultModuleFields = [
    { fieldName: 'name', label: 'Name', type: { inputType: "TextField", fieldType: "String", schemaType: "String" }, inputType: { inputType: 'TextField', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: true },
    { fieldName: "updatedAt", label: "Updated At", type: { inputType: "Date", fieldType: "Date", schemaType: "Date" }, inputType: { inputType: 'Date', schemaType: 'Date' }, ref: null, dropdown: null, default: null, required: false, massUpdatable: false },
    { fieldName: "createdAt", label: "Created At", type: { inputType: "Date", fieldType: "Date", schemaType: "Date" }, inputType: { inputType: 'Date', schemaType: 'Date' }, ref: null, dropdown: null, default: null, required: false, massUpdatable: false },
    { fieldName: "created_by", label: "Created By", type: { inputType: "Relate", fieldType: "Relate", schemaType: "Relate" }, inputType: { inputType: 'Relate', schemaType: 'Relate' }, ref: "users", dropdown: null, default: null, required: false, massUpdatable: false },
    { fieldName: "modified_by", label: "Last Modified By", type: { inputType: "Relate", fieldType: "Relate", schemaType: "Relate" }, inputType: { inputType: 'Relate', schemaType: 'Relate' }, ref: "users", dropdown: null, default: null, required: false, massUpdatable: false },
    { fieldName: "assigned_to", label: "Assigned User", type: { inputType: "Relate", fieldType: "Relate", schemaType: "Relate" }, inputType: { inputType: 'Relate', schemaType: 'Relate' }, ref: "users", dropdown: null, default: null, required: false, massUpdatable: false },
    { fieldName: 'description', label: 'Description', type: { inputType: "TextArea", fieldType: "String", schemaType: "String" }, inputType: { inputType: 'TextArea', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false, massUpdatable: false },
    { fieldName: 'deleted', label: 'Deleted', type: { inputType: "Checkbox", schemaType: "Boolean", fieldType: "Boolean" }, inputType: { inputType: 'Checkbox', schemaType: 'Boolean' }, dropdown: null, ref: null, default: false, required: false },
    { fieldName: "client", label: "Client", type: { inputType: "Relate", fieldType: "Object", schemaType: "Object" }, inputType: { inputType: 'Relate', schemaType: 'Relate' }, ref: "clients", dropdown: null, default: null, required: false, massUpdatable: false },
  ];

  public personModuleFields = [
    { fieldName: 'current_address', label: 'Current Address', type: { inputType: "Address", schemaType: "Object", fieldType: "Object" }, inputType: { inputType: 'Address', fieldType: 'Object', schemaType: 'Object' }, dropdown: null, ref: null, default: null, required: false },
    { fieldName: 'department', label: 'Department', type: { inputType: "Dropdown", schemaType: "Object", fieldType: "Object" }, inputType: { inputType: 'Dropdown', schemaType: 'Object' }, dropdown: null, ref: null, default: null, required: false },
    { fieldName: 'email', label: 'Email', type: { inputType: "EmailAddress", schemaType: "Object", fieldType: "Object" }, inputType: { inputType: 'EmailAddress', schemaType: 'Object' }, dropdown: null, ref: null, default: null, required: false },
    { fieldName: 'first_name', label: 'First Name', type: { inputType: "TextField", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'TextField', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'last_name', label: 'Last Name', type: { inputType: "TextField", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'TextField', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'home_phone', label: 'Home Phone', type: { inputType: "Phone", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'Phone', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'mobile_phone', label: 'Mobile Phone', type: { inputType: "Phone", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'Phone', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'office_phone', label: 'Office Phone', type: { inputType: "Phone", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'Phone', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'other_phone', label: 'Other Phone', type: { inputType: "Phone", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'Phone', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'title', label: 'Title', type: { inputType: "Dropdown", schemaType: "Object", fieldType: "Object" }, inputType: { inputType: 'Dropdown', schemaType: 'Object' }, dropdown: null, ref: null, default: null, required: false },
  ];

  public companyModuleFields = [
    { fieldName: 'billing_address', label: 'Billing Address', type: { inputType: "Address", schemaType: "Object", fieldType: "Object" }, inputType: { inputType: 'Address', fieldType: 'Object', schemaType: 'Object' }, dropdown: null, ref: null, default: null, required: false },
    { fieldName: 'shipping_address', label: 'Shipping Address', type: { inputType: "Address", schemaType: "Object", fieldType: "Object" }, inputType: { inputType: 'Address', fieldType: 'Object', schemaType: 'Object' }, dropdown: null, ref: null, default: null, required: false },
    { fieldName: 'office_phone', label: 'Office Phone', type: { inputType: "Phone", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'Phone', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'fax_phone', label: 'Fax Phone', type: { inputType: "Phone", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'Phone', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'website', label: 'Website', type: { inputType: "Url", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'Url', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
  ];

  public fileModuleFields = [
    { fieldName: 'filename', label: 'File Name', type: { inputType: "TextField", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'TextField', schemaType: 'String' }, dropdown: null, ref: null, default: '', required: false },
    { fieldName: 'path', label: 'Path', type: { inputType: "File", schemaType: "String", fieldType: "String" }, inputType: { inputType: 'File', schemaType: 'String' }, dropdown: null, ref: null, default: '', description: "File field type", required: false },
    { fieldName: 'exp_date', label: 'Expiration Date', type: { inputType: "Date", schemaType: "Date", fieldType: "Date" }, inputType: { inputType: 'Date', schemaType: 'Date' }, dropdown: null, ref: null, default: null, required: false }
  ];

  public fieldTypes = [
    { inputType: 'Address', fieldType: 'Object', schemaType: 'Object' },
    { inputType: 'Array', schemaType: 'Array' },
    { inputType: 'AutoIncrement', schemaType: 'Number' },
    { inputType: 'Checkbox', schemaType: 'Boolean' },
    { inputType: 'Currency', schemaType: 'Number' },
    { inputType: 'Date', schemaType: 'Date' },
    { inputType: 'DateTime', schemaType: 'Date' },
    { inputType: 'Dropdown', schemaType: 'Object' },
    { inputType: 'EmailAddress', schemaType: 'Object' },
    { inputType: 'File', schemaType: 'String' },
    { inputType: 'FlexRelate', schemaType: 'Object' },
    { inputType: 'ListField', schemaType: 'Array' },
    { inputType: 'MessageListField', schemaType: 'Array' },
    { inputType: 'Module', schemaType: 'Object' },
    { inputType: 'MultiFlexRelate', schemaType: 'Array' },
    { inputType: 'MultiModule', schemaType: 'Array' },
    { inputType: 'MultiRelate', schemaType: 'Array' },
    { inputType: 'MultiSelect', schemaType: 'Array' },
    { inputType: 'Number', schemaType: 'Number' },
    { inputType: 'Object', schemaType: 'Object' },
    { inputType: 'Phone', schemaType: 'String' },
    { inputType: 'Relate', schemaType: 'Relate' },
    { inputType: 'TextArea', schemaType: 'String' },
    { inputType: 'TextField', schemaType: 'String' },
    { inputType: 'Url', schemaType: 'String' }
  ];

  // save in local storage so we don't lose any data we may be working on
  storageKey: string = 'new-module';

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private _sharedUtilsService: SharedUtilsService
  ) { }


  setCurrent(_module: SsModule): void {
    this.currentModule = _module;
    // this._sharedUtilsService.saveInLocal(this.storageKey, _module);
  }


  getCurrent(): SsModule {
    if (!this.currentModule) {
      // console.log('No current module detected. Getting it from storage! We must clear it in local storage once one is successfully created OR cancelled!');
      // must have lost it. Get from local storage and set it back.
      // we must clear it in local storage once one is successfully created OR cancelled!
      // this.currentModule = this._sharedUtilsService.getFromLocal(this.storageKey);
    }

    return this.currentModule;
  }


  setFieldUpdateData(_updateData: any): void {
    this.fieldUpdateData = _updateData;
  }


  getFieldUpdateData(): any {
    return this.fieldUpdateData;
  }


  waitForModule(_moduleName): Promise<SsModule> {
    return new Promise(async (resolve, reject) => {
      let _module = null;

      // We want to clone the module before returning it, so anything that calls this does not modify the original ones store here.
      if (!this.allModules) this.allModules = await lastValueFrom(this.getAll());
      _module = this.allModules ? this._sharedUtilsService.deepClone(this.allModules.find(_m => _m.name === _moduleName)) : null;

      resolve(_module);
    });
  }


  async getFullModuleByNameWithRefresh(_moduleName) {
    // We want to clone the module before returning it, so anything that calls this does not modify the original ones store here.
    if (!this.allModules) this.allModules = await lastValueFrom(this.getAll());
    return this.allModules ? this._sharedUtilsService.deepClone(this.allModules.find(_m => _m.name === _moduleName)) : null;
  }


  async getFullModuleByIdWithRefresh(_moduleId): Promise<SsModule> {
    return new Promise(async (resolve, reject) => {
      let moduleToFind = null;

      if (!this.allModules) this.allModules = await lastValueFrom(this.getAll());

      // We want to clone the module before returning it, so anything that calls this does not modify the original ones store here.
      moduleToFind = this.allModules ? this._sharedUtilsService.deepClone(this.allModules.find(_m => _m._id === _moduleId)) : null;
      resolve(moduleToFind);
    });
  }


  async getRelatedFieldsInSearch(_fullModule, _searchTerms, _gridFields): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      // console.log('SEARCH TERMS: ', _searchTerms);
      const _moduleFields = _fullModule.fields;

      // console.log('Full Module Fields: ', _moduleFields);

      let currentSearchFields = null;

      if (_searchTerms && _searchTerms['inclusionSearch']) {
        // console.log('Building field from search term inclusion stuff: ', _searchTerms['inclusionSearch']);
        currentSearchFields = Object.entries(_searchTerms['inclusionSearch']).map(([k, v]) => ({ fieldName: k }));
      } else {
        // filter out other shit
        currentSearchFields = _searchTerms;
      }

      if (currentSearchFields.fields) currentSearchFields = currentSearchFields.fields;

      // console.log('currentSearchFields: ', currentSearchFields);

      let fieldsNeedingAggregate = (currentSearchFields) ? currentSearchFields.filter(_stf => !_moduleFields.find(_mf => _mf.fieldName === _stf.fieldName)) : [];
      // console.log('Fields needing aggregate: ', fieldsNeedingAggregate);

      // need full fields here with fieldMapping field...
      const mappedFields = fieldsNeedingAggregate.map(_aggField => {
        // if it is in the grid fields, use that one.
        // console.log('AGG SEARCH FIELD: ', _aggField);

        let fieldInfo = _gridFields.find(_gf => _gf.fieldName === _aggField.fieldName);
        // console.log('Field Info 1: ', fieldInfo);

        if (!fieldInfo) {
          // console.log('Find field in basic search. Only other place it can be');
          const basicSearchLayout = _fullModule.layouts.find(_l => _l.view === 'basic-search');

          if (basicSearchLayout && basicSearchLayout.sections && basicSearchLayout.sections.length) {
            const basicSearchFields = basicSearchLayout.sections[0].sectionFields;
            // console.log('basicSearchFields: ', basicSearchFields);
            fieldInfo = basicSearchFields.find(_gf => _gf.fieldName === _aggField.fieldName);
          }
          // console.log('Field Info 2: ', fieldInfo);
        }

        return fieldInfo;
      });

      // return fields that are in the current search, but not in the module      
      // console.log('fieldsNeedingAggregate final: ', mappedFields);

      resolve(mappedFields);
    });
  }


  getFullModuleByName(_moduleName): SsModule {
    // We want to clone the module before returning it, so anything that calls this does not modify the original ones store here.
    return this.allModules ? this._sharedUtilsService.deepClone(this.allModules.find(_m => _m.name === _moduleName)) : null;
  }


  async getLeadStatusField(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let leadStatusField = null;

      const leadsModule = this.getFullModuleByName('leads');
      const leadFields = (leadsModule?.fields?.length) ? [...leadsModule.fields] : [];
      const foundField = leadFields.find(_f => _f?.fieldName === this.leadStatusFieldName);

      // If field was found we want to clone it before returning, so anything that calls this does not modify the original one on module.
      leadStatusField = (foundField?.fieldName) ? this._sharedUtilsService.deepClone(foundField) : null;

      resolve(leadStatusField);
    });
  }


  async getModuleFieldsWithRelationships(_module: SsModule, excludeManyToMany: boolean = false, allowReverseRelationships: boolean = false): Promise<any[]> {
    return new Promise(async (resolve) => {
      let fieldsToReturn: any[] = null;

      if (_module != undefined) {
        let fullModule = null;

        // If all params are present use passed module
        if (_module._id != undefined && _module.name != undefined && _module.fields != undefined && _module.relationships != undefined) {
          fullModule = _module;
        } else if (_module.name != undefined) {
          // Has name so get full module by that
          fullModule = await this.getFullModuleByNameWithRefresh(_module.name);
        } else {
          // Name was not present so get full module by id
          const moduleId = (_module._id != undefined) ? _module._id : _module;
          fullModule = await this.getFullModuleByIdWithRefresh(moduleId);
        }

        if (fullModule != undefined && fullModule.name != undefined) {
          const moduleFields = (fullModule.fields != undefined) ? this._sharedUtilsService.deepClone(fullModule.fields) : null; // Making clone since we are pushing onto it
          const relationships = (fullModule.relationships != undefined && fullModule.relationships.length) ? this._sharedUtilsService.deepClone(fullModule.relationships) : null;
          // console.log('relationships: ', relationships);

          if ((moduleFields && moduleFields.length) && (relationships && relationships.length)) {
            let filteredRelationships = relationships; // Setting to relationships for init.

            // Determine which filter to use.
            if (!allowReverseRelationships) filteredRelationships = relationships.filter(_f => _f?.related_module?.name != undefined && _f.related_module.name === fullModule?.name && (!excludeManyToMany || (_f?.type !== "Many to Many")));
            else filteredRelationships = relationships.filter(_f => _f?.related_module?.name != undefined && (!excludeManyToMany || _f?.type !== "Many to Many"));

            // console.log('filteredRelationships: ', filteredRelationships);

            filteredRelationships.forEach(_r => {
              // Only push if not present in fields already
              // console.log('_r: ', _r);

              if (moduleFields.findIndex(_f => _f['fieldName'] === _r?.name) == -1) {
                const primaryModuleName = (_r?.primary_module?.name) ? _r.primary_module.name : null;
                const relatedModuleName = (_r?.related_module?.name) ? _r.related_module.name : null;
                const isReverseLookup = (relatedModuleName !== fullModule?.name) ? true : false;
                const partToUse = isReverseLookup ? relatedModuleName : primaryModuleName;

                let builtLabel = (partToUse) ? 'Related ' + partToUse.charAt(0).toUpperCase() + partToUse.slice(1, -1) : partToUse; // Replace first letter with uppercase and remove the "s" from end.
                // console.log("Built Label: ", builtLabel);

                const newRelateField = {
                  field: _r?.name,
                  fieldName: _r?.name,
                  inputType: { inputType: 'Relate', schemaType: 'Relate' },
                  label: builtLabel,
                  ref: partToUse,
                  required: false,
                  default: null,
                  dropdown: null,
                  type: { inputType: 'Relate', schemaType: 'Relate' },
                  isReverseLookup: isReverseLookup
                };

                moduleFields.push(newRelateField);
              } else {
                // console.log('Filtering out relationship due to already being present: ', _r.name);
              }
            });
          }

          fieldsToReturn = moduleFields;
        }
      } else {
        console.log('Passed Module was undefined');
      }

      resolve(fieldsToReturn);
    });
  }


  async updateModuleLayouts(_moduleForUpdates: SsModule): Promise<SsModule> {
    return new Promise(async (resolve) => {
      let moduleToUpdate = this._sharedUtilsService.deepClone(_moduleForUpdates);

      if (moduleToUpdate?._id) {
        const fieldUpdateData = await this.getFieldUpdateData(); // Getting stored field update data to use in function

        // If False this is a new field, or the original field was not set to fieldUpdateData
        if (fieldUpdateData && (fieldUpdateData.originalField && fieldUpdateData.originalField !== undefined) && (fieldUpdateData.newField && fieldUpdateData.newField !== undefined)) {
          const dataForUpdate = {
            moduleId: moduleToUpdate._id,
            originalField: fieldUpdateData.originalField,
            newField: fieldUpdateData.newField
          }

          const layoutFieldsUpdate = await lastValueFrom(this.moduleLayoutLabelsUpdate(dataForUpdate));
          if (layoutFieldsUpdate && layoutFieldsUpdate !== undefined) {
            moduleToUpdate = layoutFieldsUpdate as SsModule;

            // If a field is add above to the currentModules fields and we don't want it to be a hidden field add fieldName here... ex: ["name", "deleted"];
            moduleToUpdate.customSchema = this.fieldsToSchema(moduleToUpdate.fields); // convert array of fields to object used for a mongoose schema.
          }
        }
      }

      resolve(moduleToUpdate);
    });
  }


  getShallow(): Observable<SsModule[]> {
    return this.httpClient.get<SsModule[]>(this.API_URL + 'shallow');
  }


  removeRedisById(_id): Observable<SsModule[]> {
    return this.httpClient.get<SsModule[]>(this.API_URL + 'remove/redisById/' + _id);
  }


  listenForStepChange() {
    return this.stepChanged$;
  }


  announceStepChange(newStep: number) {
    this.stepChanged$.next(newStep);
  }


  listenForModuleChange() {
    return this.customModuleChanged$;
  }


  announceModuleChange(_module: SsModule) {
    this.customModuleChanged$.next(_module);
  }


  listenForModuleChosen() {
    return this.customModuleChosen$;
  }


  announceModuleChosen(_module: SsModule) {
    this.customModuleChosen$.next(_module);
  }


  get(_id: string): Observable<SsModule> {
    return this.httpClient.get<SsModule>(this.API_URL + _id);
  }


  findByName(_name: string): Observable<SsModule> {
    return this.httpClient.get<SsModule>(this.API_URL + 'by-name/' + _name);
  }


  getUncached(): Observable<SsModule[]> {
    return this.httpClient.get<SsModule[]>(this.API_URL);
  }


  getLayout(_layoutId: string): Observable<Layout> {
    return this.httpClient.get<Layout>(this.LAYOUTS_API + _layoutId);
  }


  getShallowModules(): Observable<SsModule[]> {
    return this.httpClient.get<SsModule[]>(this.API_URL + 'shallow-modules');
  }


  getAll(): Observable<any[]> {
    // smarter than just going to api every time. Getting them all, so just cache for use in dropdowns all over
    if (this._sharedUtilsService.isStorageValid(this.modulesStorage)) {
      // console.log('Saved an API call for SS Modules. Retreived from cache.');
      return this.modulesStorage.itemCache;
    }

    this.modulesStorage = this._sharedUtilsService.updateStorageExpiration(this.modulesStorage);
    // console.log('Set locations storage to: ', this.locationsStorage);
    return this.modulesStorage.itemCache = cacheable<any>(this.httpClient.get<any[]>(this.API_URL));
  }


  // Get all modules with modules not allowed for reports filtered out.
  async getModulesAllowedForReports(): Promise<any[]> {
    return new Promise(async (resolve) => {
      let modulesForReports: any[] = [];
      const allModules = await lastValueFrom(this.getAll());
      if (allModules != undefined && allModules.length) {
        modulesForReports = allModules.filter((_module) => { return !this.modulesToLeaveOutOfReports.includes(_module.name) }); // Remove unwanted modules
      }

      resolve(modulesForReports);
    });
  }


  create(_module): Observable<SsModule> {
    return this.httpClient.post<SsModule>(this.API_URL, JSON.stringify(_module), this.headerOptions);
  }


  update(_module: SsModule): Observable<SsModule> {
    return this.httpClient.put<SsModule>(`${this.API_URL}${_module._id}`, JSON.stringify(_module), this.headerOptions);
  }


  search(searchTerms): Observable<SsModule[]> {
    return this.httpClient.post<SsModule[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  moduleLayoutLabelsUpdate(updateData): Observable<SsModule> {
    return this.httpClient.post<SsModule>(this.API_URL + 'update/moduleLayoutLabels', JSON.stringify(updateData), this.headerOptions);
  }


  moduleLayoutFieldRemoval(removalData): Observable<SsModule> {
    return this.httpClient.post<SsModule>(this.API_URL + 'remove/moduleLayoutField', JSON.stringify(removalData), this.headerOptions);
  }


  getRelatedModules(_moduleId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.API_URL}${_moduleId}/related-modules`);
  }


  getModuleRelationships(_moduleId: string): Observable<SsModule[]> {
    return this.httpClient.get<SsModule[]>(`${this.API_URL}${_moduleId}/relationships`);
  }


  triggerPm2Restart(): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'trigger-pm2-restart');
  }


  rebuildModels(): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'rebuild-models');
  }


  reportCurrentModels(): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'report-models');
  }


  lowerCasedFirstLetter(val) {
    return String(val).charAt(0).toLowerCase() + String(val).slice(1);
  }


  getBaseRouteForModule(_moduleName: string) {
    // console.log('_moduleName: ', _moduleName);

    const moduleNameLowerCase = (_moduleName && _moduleName.length) ? this.lowerCasedFirstLetter(_moduleName) : '';
    // console.log('moduleNameLowerCase: ', moduleNameLowerCase);

    if (moduleNameLowerCase === 'templates') {
      return '/app/campaigns/templates';
    } else {
      return this.outOfBoxModules.includes(moduleNameLowerCase) ? `/app/${moduleNameLowerCase}` : `/app/module/${moduleNameLowerCase}`;
    }
  }


  delete(id: string): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }


  fieldsToSchema(_module: SsModule) {
    const _fields = (_module && _module.fields) ? [..._module.fields] : [];
    // console.log('Fields: ', _fields);

    // add client to any custom schema
    const customSchema = {
      client: {
        "type": "Object",
        "default": null,
        "required": false
      }
    };

    _fields.forEach(_field => {
      customSchema[_field.fieldName] = {
        type: _field.type.schemaType,
        default: _field.default,
        required: _field.required
      };

      if (_field.type.schemaType === 'Relate') {
        customSchema[_field.fieldName].type = "Object";
        customSchema[_field.fieldName]['ref'] = _field.ref;
      }
    });

    if (_module.relationships) {
      _module.relationships.filter(_r => _r && _r.primary_module && _r.related_module).forEach(_r => {
        // console.log('Rel: ', _r);

        if (_r && _r.primary_module && _r.primary_module.name !== _module.name) {
          customSchema[_r.primary_module.name] = {
            type: "Object",
            fieldName: _r.primary_module.name,
            label: _r.primary_module.label,
            ref: _r.primary_module.name,
            default: null,
            required: false
          };
        }
      });
    }

    return customSchema;
  }


  fieldFormGroup(formData: SchemaField) {
    return this.formBuilder.group({
      fieldName: [formData.fieldName, [Validators.required, CustomValidator.invalidFieldName]],
      label: [formData.label, Validators.required],
      type: [formData.type],
      dropdown: [formData.dropdown],
      dependent: [formData.dependent],
      parentField: [formData.parentField],
      fieldsToTrigger: [formData.fieldsToTrigger],
      inputType: [formData.inputType, Validators.required],
      ref: [formData.ref],
      textAreaRowCount: [formData.textAreaRowCount],
      description: [formData.description],
      default: [formData.default],
      required: [formData.required]
    });
  }


  moduleFormGroup(formData: SsModule) {
    return this.formBuilder.group({
      name: [formData.name, [Validators.required, CustomValidator.invalidModelName]],
      label: [formData.label, Validators.required],
      description: [formData.description],
      fields: [formData.fields],
      apps: [formData.apps],
      custom: [formData.custom],
      customSchema: [formData.customSchema],
      active: [formData.active],
      locked: [formData.locked],
      deleted: [formData.deleted],
      createdAt: [formData.createdAt],
      updatedAt: [formData.updatedAt]
    });
  }
}