import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MintNotificationComponent } from 'src/app/modules/notifications/mint-notification/mint-notification.component';

import { User } from '../../../models/user';
import { AuthService, SharedUtilsService, UserService } from '../../../services';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: User = new User();
  errorMessage: string = '';

  rememberMe: boolean = false;
  isLoading: boolean = false;

  private rememberCookieKey: string = 'remember-me';

  isJumpRoute: boolean = false; // Lets us know if jump assets and name need to be displayed.

  constructor(
    private router: Router,
    private _cookieService: CookieService,
    private userService: UserService,
    private authService: AuthService,
    private _sharedUtilsService: SharedUtilsService,
    public snackBar: MatSnackBar
  ) {
    const urlSource = window.location.href;
    const routeSrc = this.router?.url;
    
    // console.log('Url Source: ', urlSource);
    // console.log('routeSrc: ', routeSrc);

    // Check if "jumphire" exists in either the full window URL or the Angular route URL.
    this.isJumpRoute = urlSource.includes('jumphire') || routeSrc?.includes('jumphire');
  }

  ngOnInit() {
    this.setEmailFromCookie();
  }


  setEmailFromCookie() {
    const emailFromCookie = this._cookieService.get(this.rememberCookieKey);

    if (emailFromCookie) {
      this.user.email = emailFromCookie;
      this.rememberMe = true;
    }
  }


  saveEmailInCookie() {
    if (this.rememberMe) this._cookieService.set(this.rememberCookieKey, this.user.email);
    else this._cookieService.delete(this.rememberCookieKey);
  }


  async login() {
    this.isLoading = true;

    this.saveEmailInCookie();

    const userLoginAttempt = await lastValueFrom(this.userService.login(this.user));

    if (userLoginAttempt.user && userLoginAttempt.status !== false) {
      this.authService.setToken(userLoginAttempt.token);
      this.authService.setUserIdle(); 

      this._sharedUtilsService.previousRoute = '';

      const userToSave = userLoginAttempt.user;

      if (userLoginAttempt.teams) {
        userToSave.teams = userLoginAttempt.teams;
      }

      this.authService.setCurrentlyLoggedInUser(userToSave);

      this.sendToPortal(userLoginAttempt);
    } else {
      this.errorMessage = userLoginAttempt['error'];
      this.isLoading = false;
    }
  }


  setEmailInStorage() {
    localStorage.setItem('login_email', this.user.email);
  }


  toggleRememberMe() {
    this.rememberMe = !this.rememberMe;
  }


  sendToPortal(userLoginAttempt) {
    this.snackBar.openFromComponent(MintNotificationComponent, {
      duration: 1500,
      horizontalPosition: 'start',
      data: {
        class: 'success',
        message: 'Welcome ' + userLoginAttempt?.user?.first_name + '. Login Successful!'
      }
    });

    const redirectUrl = this.authService.redirectUrl || null; // Default to null if no redirect URL was set. Is set when user enters a url when not signed in.

    let _route = (redirectUrl != undefined) ? redirectUrl : '/app/dashboard';
    if (userLoginAttempt?.user?.userType === 'Dashboard') _route = '/dashboard';
    else if (userLoginAttempt?.user?.userType === 'Provider') _route = '/provider/dashboard';

    this.router.navigate([_route]);
    this.isLoading = false;
  }
}