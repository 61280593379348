import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';

import { VoiceshotDialogComponent } from '@app/modules/dialogs/voiceshot-dialog/voiceshot-dialog.component';
import { VoiceShot } from '@app/models';

import { AuthService } from './auth.service';
import { MessageService } from './message.service';
import { SharedUtilsService } from './shared-utils.service';

export interface VoiceshotApi {
  items: VoiceShot[];
  total_count: number;
}

@Injectable({
  providedIn: 'root'
})
export class VoiceshotService {
  private API_URL = '/api/voiceshots/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private currentVoiceShotBeingViewed;

  public currentSearch = {
    params: null,
    results: null
  };

  form: UntypedFormGroup;

  constructor(
    private httpClient: HttpClient,
    public snackBar: MatSnackBar,
    private _voiceshotDialog: MatDialog,
    private _authService: AuthService,
    private _sharedUtilsService: SharedUtilsService,
    private _messageService: MessageService,
    private formBuilder: UntypedFormBuilder
  ) { }


  setCurrentBeingViewed(_voiceshot: VoiceShot): void {
    this.currentVoiceShotBeingViewed = _voiceshot;
  }


  getCurrentBeingViewed(): VoiceShot {
    return this.currentVoiceShotBeingViewed;
  }


  create(_voiceshot: VoiceShot): Observable<VoiceShot> {
    return this.httpClient.post<VoiceShot>(this.API_URL, JSON.stringify(_voiceshot), this.headerOptions);
  }


  update(_voiceshot: VoiceShot): Observable<VoiceShot> {
    return this.httpClient.put<VoiceShot>(this.API_URL + _voiceshot._id, JSON.stringify(_voiceshot), this.headerOptions);
  }


  get(_id: string): Observable<VoiceShot> {
    return this.httpClient.get<VoiceShot>(this.API_URL + _id);
  }


  getAll(): Observable<VoiceShot[]> {
    return this.httpClient.get<VoiceShot[]>(this.API_URL);
  }


  deleteByIdArray(arrayOfIds): Observable<any> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.post<any>(this.API_URL + 'delete-by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  delete(_id: string): Observable<any> {
    return this.httpClient.delete(this.API_URL + _id);
  }


  search(searchTerms): Observable<VoiceShot[]> {
    return this.httpClient.post<VoiceShot[]>(this.API_URL + 'advancedSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  updateVoiceshotFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<VoiceShot[]> {
    const updateRequest = { ids: arrayOfIds, fields: fieldsToUpdate };
    return this.httpClient.put<VoiceShot[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<VoiceShot[]> {
    return this.httpClient.put<VoiceShot[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  selectAllSearch(searchTerms: VoiceShot): Observable<VoiceShot[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<VoiceShot[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getDatasource(searchParams, sortField = 'last_name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<VoiceshotApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<VoiceshotApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  formGroup(formData: VoiceShot) {
    return this.formBuilder.group({
      _id: [formData._id],
      title: [formData.title, Validators.required],
      description: [formData.description],
      path: [formData.path],
      filename: [formData.filename],
      created_by: [formData.created_by],
      modified_by: [formData.modified_by],
      createdAt: [formData.createdAt],
      updatedAt: [formData.updatedAt]
    });
  }


  showBlastVoiceshot(_recipients, _voiceshots, _confirmText = null) {
    return new Promise(async (resolve) => {
      const dialogRef = this._voiceshotDialog.open(VoiceshotDialogComponent, { panelClass: 'voiceshot-panel', disableClose: true });
      const instance = dialogRef.componentInstance;

      // console.log('_recipients for voiceshot: ', _recipients)

      instance.recipients = _recipients;
      instance.availableVoiceshots = _voiceshots;
      if (_confirmText) instance.confirmText = _confirmText;

      let voiceshotDetails = null;

      const dialogResp = await lastValueFrom(dialogRef.afterClosed());

      if (dialogResp.confirmed === true) {
        voiceshotDetails = {
          voiceshot: dialogResp?.voiceshotChosen,
          recipients: _recipients
        };

        // console.log('Sending these details to processBlastVoiceshot: ', voiceshotDetails);

        if (_recipients && _recipients.length) {
          await lastValueFrom(this._messageService.processBlastVoiceshot(voiceshotDetails));
          this.snackBar.open('Success', 'Voiceshot was triggered', { duration: 2000 });
        }
      }

      resolve(voiceshotDetails);
    });
  }
}